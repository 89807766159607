define('infrastructure/modules/bidirectional/CsfDirDirective',["require", "exports", 'ngModule'], function (require, exports, ngModule) {
    "use strict";
    ngModule.directive('csfDir', [function () {
            return {
                restrict: 'A',
                link: function ($scope, element, attrs) {
                    $scope.$on('rtl-changed', function (event, dirValue) {
                        element.attr('dir', dirValue || 'ltr');
                    });
                }
            };
        }]);
});

