define('login-component/localization/Localize',["require", "exports"], function (require, exports) {
    "use strict";
    var LocalizeDirective = (function () {
        function LocalizeDirective(lcI18nService, render) {
            var _this = this;
            this.lcI18nService = lcI18nService;
            this.render = render;
            this.restrict = 'A';
            this.link = function ($scope, element, attrs) {
                _this.render($scope, element, attrs, _this.lcI18nService);
            };
        }
        return LocalizeDirective;
    }());
    exports.register = function (ngModule) {
        ngModule.directive("csfLocalize", ['csfI18nService', function (csfI18nService) { return new LocalizeDirective(csfI18nService, function (scope, element, attr, i18nService) {
                var key = attr.csfLocalize;
                var result;
                if (key) {
                    result = i18nService.getResource(key);
                    if (_.isUndefined(result)) {
                        result = key;
                    }
                }
                element.html(result);
            }); }]);
        ngModule.directive("csfLocalizeAttr", ['csfI18nService', function (csfI18nService) { return new LocalizeDirective(csfI18nService, function (scope, element, attr, i18nService) {
                var attributes = scope.$eval(attr.csfLocalizeAttr);
                _.each(attributes, function (value, name) {
                    var key = value;
                    var result;
                    if (key) {
                        result = i18nService.getResource(key);
                        if (_.isUndefined(result)) {
                            result = key;
                        }
                    }
                    element.attr(name, result);
                });
            }); }]);
    };
});

