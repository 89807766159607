define('login-component/Services/BidirectionalService',["require", "exports"], function (require, exports) {
    "use strict";
    var BidirectionalServiceImpl = (function () {
        function BidirectionalServiceImpl($rootScope) {
            this.$rootScope = $rootScope;
        }
        BidirectionalServiceImpl.prototype.findLocale = function (locale) {
            if (!this.locales || this.locales.length == 0) {
                return undefined;
            }
            return _.find(this.locales, function (loc) { return loc.locale.toLowerCase() === locale.toLowerCase() ||
                locale.slice(0, 2).toLowerCase() === loc.locale.toLowerCase(); });
        };
        BidirectionalServiceImpl.prototype.isRtlLocale = function (locale) {
            var foundLocale = this.findLocale(locale);
            if (!foundLocale) {
                return undefined;
            }
            return foundLocale.rtl;
        };
        BidirectionalServiceImpl.prototype.getLocaleDirection = function (locale) {
            var isRTLValue = this.isRtlLocale(locale);
            if (isRTLValue === undefined) {
                return undefined;
            }
            else {
                return isRTLValue ? 'rtl' : 'ltr';
            }
        };
        BidirectionalServiceImpl.prototype.fireRtlChanged = function (direction) {
            this.$rootScope.$broadcast('rtl-changed', direction);
        };
        BidirectionalServiceImpl.prototype.setLocales = function (locales) {
            this.locales = locales;
        };
        BidirectionalServiceImpl.prototype.setLocale = function (locale) {
            if (!locale) {
                return;
            }
            var newDirection = this.getLocaleDirection(locale);
            if (!newDirection) {
                throw new Error('Can not determine locale direction. Please, check environment configuration.');
            }
            this.fireRtlChanged(newDirection);
        };
        return BidirectionalServiceImpl;
    }());
    exports.BidirectionalServiceImpl = BidirectionalServiceImpl;
    ;
    exports.register = function (ngModule) {
        ngModule.factory('bidirectionalService', [
            '$rootScope',
            function ($rootScope) {
                return new BidirectionalServiceImpl($rootScope);
            }
        ]);
    };
});

