define('login-component/Services/LoginService',["require", "exports", './Authenticator'], function (require, exports, authenticatorModule) {
    "use strict";
    function readSSOData(ssoObject, system, enviroName) {
        try {
            var activated;
            var userId = null;
            var authToken = null;
            var site = null;
            if (ssoObject.Activate(system, enviroName)) {
                console.log('failed to activate SSO');
                return null;
            }
            activated = true;
            try {
                if (!ssoObject.GetAuthenticationToken(enviroName)) {
                    authToken = ssoObject.AuthenticationToken_token;
                }
            }
            catch (e) {
                console.log('failed to get authToken from SSO:' + e);
            }
            if (!ssoObject.GetUserStrongSecInfo(enviroName)) {
                userId = ssoObject.UserStrongSecInfo_uxname;
            }
            if (!ssoObject.GetUserSiteInfo(enviroName)) {
                ssoObject.GetUserLangInfo(enviroName);
                if (ssoObject.UserSiteInfo_sSite) {
                    site = { id: ssoObject.UserSiteInfo_sSite, languageId: ssoObject.UserLangInfo_sLang };
                }
            }
            return { authToken: authToken, userId: userId, site: site };
        }
        catch (e) {
            console.log('error during communication with SSO:' + e);
        }
        finally {
            if (activated) {
                try {
                    ssoObject.Deactivate(system, enviroName);
                }
                catch (e) { }
            }
        }
    }
    var LoginService = (function () {
        function LoginService(interpolateService, qService, intervalService, securityService, lcI18nService, config, bidiService) {
            var _this = this;
            this.interpolateService = interpolateService;
            this.qService = qService;
            this.intervalService = intervalService;
            this.securityService = securityService;
            this.lcI18nService = lcI18nService;
            this.config = config;
            this.bidiService = bidiService;
            this.disclaimer = '';
            this.clientLogoInBase64 = '';
            this.renewSession = function () {
                _this.securityService
                    .renewSession(_this.config.wasUserActive && _this.config.wasUserActive())
                    .error(function (response, status) {
                    if (status === 401) {
                        _this.disableSessionTimer();
                        _this.config.sessionEvent && _this.config.sessionEvent({ expired: true });
                    }
                });
            };
            this.executionState = securityService.executionState;
        }
        LoginService.prototype.setUIFactory = function (uiFactory) {
            this.uiFactory = uiFactory;
        };
        LoginService.prototype.disableSessionTimer = function () {
            if (this.sessionTimer) {
                this.intervalService.cancel(this.sessionTimer);
                this.sessionTimer = null;
            }
        };
        LoginService.prototype.enableSessionTimer = function (renewInterval) {
            if (renewInterval) {
                this.sessionTimer = this.intervalService(this.renewSession, Math.max(renewInterval * 1000, 60000) / 2);
            }
        };
        LoginService.prototype.logout = function () {
            var logoutDefferedResult = this.qService.defer();
            this.disableSessionTimer();
            this.securityService
                .logout()
                .success(function (response) {
                logoutDefferedResult.resolve({});
            })
                .error(function (err) {
                logoutDefferedResult.reject(err);
            });
            return logoutDefferedResult.promise;
        };
        LoginService.prototype.logoutSession = function () {
            var logoutDefferedResult = this.qService.defer();
            this.disableSessionTimer();
            this.securityService
                .logoutSession()
                .success(function (response) {
                logoutDefferedResult.resolve({});
            })
                .error(function (err) {
                logoutDefferedResult.reject(err);
            });
            return logoutDefferedResult.promise;
        };
        LoginService.prototype.login = function (params) {
            var _this = this;
            if (!this.uiFactory) {
                throw 'LoginComponent: UI Factory is not registered, use csf-login-component directive or register own UI factory with setUIFactory method';
            }
            if (!params) {
                params = {};
            }
            var loginSSOResult = this.qService.defer();
            if (this.config.getChangeSite && this.config.getChangeSite()) {
                this.securityService
                    .getLoginInfoByOtp(params.ssoOtp)
                    .success(function (response) {
                    if (response.authenticationResult) {
                        loginSSOResult.resolve(response);
                    }
                    else {
                        _this.uiFactory.showError(_this.lcI18nService.getResource('Login.Authenticator.failedToChangeSite.text'), null);
                    }
                })
                    .error(function (error) {
                    loginSSOResult.reject(error);
                });
            }
            else {
                var ignoreCookies_1 = this.config.getNoSso && this.config.getNoSso();
                var loginSSOFunction = function (token, id, site) {
                    _this.securityService
                        .loginSSO(params.ssoOtp, _this.config.getUseSites(), params.generateOtp, ignoreCookies_1, token, id, site)
                        .success(function (response) {
                        _this.clientLogoInBase64 = response.paramsResult.clientLogo;
                        _this.disclaimer = response.paramsResult.disclaimer;
                        loginSSOResult.resolve(response);
                    })
                        .error(function (error) {
                        loginSSOResult.reject(error);
                    });
                };
                var ssoObject = document.getElementById('SSOComponentId');
                if (ssoObject) {
                    this.securityService
                        .readSessionParams()
                        .success(function (response) {
                        var ssoData = readSSOData(ssoObject, _this.config.getSystem(), response.enviroName);
                        if (!ssoData) {
                            loginSSOFunction();
                        }
                        else {
                            if (ssoData.authToken) {
                                loginSSOFunction(ssoData.authToken, null, ssoData.site);
                            }
                            else if (ssoData.userId) {
                                loginSSOFunction(null, ssoData.userId, ssoData.site);
                            }
                            else {
                                loginSSOFunction();
                            }
                        }
                    })
                        .error(function (error) {
                        loginSSOResult.reject(error);
                    });
                }
                else {
                    loginSSOFunction();
                }
            }
            var loginDefferedResult = this.qService.defer();
            this.uiFactory.startUseCase();
            loginSSOResult.promise.then(function (response) {
                var locale = _this.config.getLocale() || response.paramsResult.defaultLocale;
                _this.bidiService.setLocale(locale);
                _this.lcI18nService.setLocale(locale).then(function () {
                    if (response.errorMessage) {
                        _this.uiFactory.showError(response.errorMessage, null);
                    }
                    else if (response.paramsResult.errorMessage) {
                        _this.uiFactory.showError(response.paramsResult.errorMessage, null);
                    }
                    else {
                        _this.sessionParams = response.paramsResult;
                        if (params.handleSecurityParams) {
                            params.handleSecurityParams(response.paramsResult.allowedWebLoginDomains);
                        }
                        if (params.reason) {
                            _this.uiFactory.showError(params.reason, null);
                        }
                        _this.authenticator = new authenticatorModule.Authenticator(_this.interpolateService, _this.qService, _this.securityService, _this.lcI18nService, _this.sessionParams, _this.config.getUseSites(), _this.config.getSystem(), _this.config.getLocale(), params.generateOtp, _this.uiFactory, _this.bidiService);
                        var authResult;
                        if (response.loginResult && !response.loginResult.errorMessage) {
                            _this.uiFactory.endUseCase();
                            _this.enableSessionTimer(response.loginResult.sessionRenewInterval);
                            loginDefferedResult.resolve({
                                sessionToken: response.loginResult.sessionId,
                                loginOtp: response.loginResult.loginOtp
                            });
                        }
                        else {
                            if (response.authenticationResult && !response.authenticationResult.errorMessage) {
                                authResult = _this.authenticator.authenticateOnSSO(response.authenticationResult);
                            }
                            else {
                                authResult = _this.authenticator.authenticateFromScratch();
                            }
                            authResult.then(function (result) {
                                _this.uiFactory.endUseCase();
                                _this.enableSessionTimer(result.renewInterval);
                                loginDefferedResult.resolve({
                                    sessionToken: result.sessionToken,
                                    loginOtp: result.loginOtp
                                });
                            });
                        }
                    }
                }),
                    function (error) {
                        _this.uiFactory.showError(null, error);
                    };
            }, function (error) {
                _this.uiFactory.showError(null, error);
            });
            return loginDefferedResult.promise;
        };
        return LoginService;
    }());
    exports.register = function (ngModule, config) {
        ngModule.factory('csfLoginService', [
            '$q',
            '$interpolate',
            '$interval',
            'csfSecurityService',
            'csfI18nService',
            'bidirectionalService',
            function ($q, $interpolate, $interval, csfSecurityService, csfI18nService, bidiService) {
                return new LoginService($interpolate, $q, $interval, csfSecurityService, csfI18nService, config, bidiService);
            }
        ]);
    };
});

