define('login-component/Components/ErrorsContainer/ErrorsContainer',["require", "exports", "text!./ErrorsContainer.html", "less!./ErrorsContainer"], function (require, exports) {
    "use strict";
    var ErrorsContainerDirective = (function () {
        function ErrorsContainerDirective(template) {
            this.template = template;
            this.scope = { config: '=' };
            this.link = function ($scope) {
                $scope.$watch("config.error", function (value) {
                    $scope.error = value ? value.replace(/(\n|\\n)/g, '<br>').trim() : value;
                });
                $scope.$watch("config.throwable", function (value) {
                    $scope.throwError = value;
                });
            };
        }
        return ErrorsContainerDirective;
    }());
    exports.register = function (ngModule, template) {
        if (!template) {
            template = require('text!./ErrorsContainer.html');
        }
        ngModule.directive("csfErrorsContainer", function () { return new ErrorsContainerDirective(template); });
    };
});

