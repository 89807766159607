requirejs.config({
    waitSeconds: 0,
    baseUrl: '.',
    less: { ieCompat: false },
    shim: {
        'jquery': { exports: '$' },
        'lodash': { exports: '_' },
        'angular': { exports: 'angular', deps: ['jquery'] },
        'angularSanitize': { deps: ['angular'] },
        'angularAnimate': { deps: ['angular'] },
        'select2': { deps: ['jquery'] }
    },
    paths: {
        'jquery': 'framework/lib/jquery-2.1.1',
        'lodash': 'framework/lib/lodash-3.9.3/lodash',
        'select2': 'framework/lib/select2-3.4.5/select2',
        'angular': 'infrastructure/lib/angular-1.4.4/angular',
        'angularSanitize': 'infrastructure/lib/angular-1.4.4/angular-sanitize',
        'angularAnimate': 'infrastructure/lib/angular-1.4.4/angular-animate',
        'purl': 'framework/lib/purl-20140618/purl'
    },
    excludeShallow: [
        'framework/lib/require-plugins/require-less-20131113/normalize',
        'framework/lib/require-plugins/require-css-20131113/normalize'
    ],
    map: {
        '*': {
            'domReady': 'framework/lib/require-plugins/requirejs-domReady-9973a3e-2.0.1/domReady',
            'text': 'framework/lib/require-plugins/text-2.0.10/text',
            'less': 'framework/lib/require-plugins/require-less-20131113/less',
            'css': 'framework/lib/require-plugins/require-css-20131113/css'
        }
    }
});
define('ngModule', [
    'angular',
    'angularSanitize',
    'angularAnimate'
], function (ng) {
    var ngModule = ng.module('ngModule', ['ngSanitize', 'ngAnimate']);
    ngModule.config(['$compileProvider', function ($compileProvider) {
            $compileProvider.debugInfoEnabled(false);
        }]);
    return ngModule;
});
requirejs([
    'domReady!',
    'ngModule',
    'angular',
    'infrastructure/modules/bidirectional/module',
    'login-page/LoginPage'
], function (document, ngModule, ng) {
    ng.bootstrap(document, [ngModule.name]);
});

define("main", function(){});

