define('login-component/Components/SiteSelectionForm/SiteSelectionForm',["require", "exports", "text!./SiteSelectionForm.html", "less!./SiteSelectionForm"], function (require, exports) {
    "use strict";
    var SiteSelectionFormDirective = (function () {
        function SiteSelectionFormDirective(template) {
            this.template = template;
            this.scope = { config: '=', resultCallback: '=' };
            this.link = function ($scope) {
                $scope.sites = $scope.config.sites;
                $scope.submit = function (site) {
                    $scope.resultCallback({ site: site });
                };
            };
        }
        return SiteSelectionFormDirective;
    }());
    exports.register = function (ngModule, template) {
        if (!template) {
            template = require('text!./SiteSelectionForm.html');
        }
        ngModule.directive("csfSiteSelectionForm", function () { return new SiteSelectionFormDirective(template); });
    };
});

