define('login-component/Components/LoginComponent/LoginComponent',["require", "exports", "text!./LoginComponent.html", "less!./LoginComponent"], function (require, exports) {
    "use strict";
    var UIFactory = (function () {
        function UIFactory(qService, scope) {
            var _this = this;
            this.qService = qService;
            this.scope = scope;
            this.askCredentials = function (predefinedLogin, predefinedDomain, domains, defaultDomain) {
                if (domains && domains.length > 0) {
                    _this.scope.credentialsPromptConfig.domains = domains;
                }
                _this.scope.credentialsPromptConfig.predefinedLogin = predefinedLogin;
                if (predefinedDomain) {
                    _this.scope.credentialsPromptConfig.predefinedDomain = predefinedDomain;
                }
                else if (defaultDomain) {
                    _this.scope.credentialsPromptConfig.predefinedDomain = defaultDomain;
                }
                _this.scope.changePasswordVisible = false;
                _this.scope.siteSelectionVisible = false;
                _this.scope.credentialsPromptVisible = true;
                _this.processCredentials = _this.qService.defer();
                return _this.processCredentials.promise;
            };
            this.askSite = function (sites) {
                _this.scope.siteSelectionConfig.sites = sites;
                _this.scope.changePasswordVisible = false;
                _this.scope.credentialsPromptVisible = false;
                _this.scope.siteSelectionVisible = true;
                _this.processSite = _this.qService.defer();
                return _this.processSite.promise;
            };
            this.askChangePassword = function (passwordInfo) {
                _this.scope.changePasswordConfig.passwordInfo = passwordInfo;
                _this.scope.credentialsPromptVisible = false;
                _this.scope.siteSelectionVisible = false;
                _this.scope.changePasswordVisible = true;
                _this.processChangePassword = _this.qService.defer();
                return _this.processChangePassword.promise;
            };
            this.showError = function (error, throwable) {
                _this.scope.errorConfig.error = (error) ? error : undefined;
                _this.scope.errorConfig.throwable = (throwable) ? throwable : undefined;
                _this.scope.errorVisible = true;
            };
            this.startUseCase = function () { };
            this.endUseCase = function () {
                _this.scope.credentialsPromptVisible = false;
                _this.scope.siteSelectionVisible = false;
                _this.scope.changePasswordVisible = false;
                _this.scope.errorVisible = false;
            };
        }
        return UIFactory;
    }());
    var LoginComponentDirective = (function () {
        function LoginComponentDirective(template, config) {
            var _this = this;
            this.template = template;
            this.config = config;
            this.scope = {};
            this.controller = ["$scope", "$q", "csfLoginService",
                function ($scope, $q, csfLoginService) {
                    _this.initializeScope($scope, _this.config);
                    var theme = _this.config.getTheme();
                    var imagesUrl = _this.config.getImagesUrl();
                    var url = imagesUrl ? _this.trimTrailingSlash(imagesUrl) : '';
                    var uiFactory = new UIFactory($q, $scope);
                    $scope.uiFactory = uiFactory;
                    csfLoginService.setUIFactory(uiFactory);
                    $scope.loginService = csfLoginService;
                    $scope.backgroundImageUrl = imagesUrl ? (url + '/background.png') : theme ? '' : 'images/login_background.png';
                    $scope.appLogoUrl = imagesUrl ? (url + '/logo.png') : 'images/scc_logo_big.png';
                    $scope.sccLogoUrl = imagesUrl ? 'images/scc_logo_big.png' : '';
                    $scope.showClientLogo = !!imagesUrl;
                    $scope.$watch('loginService.clientLogoInBase64', function (clientLogoInBase64) {
                        $scope.clientLogoUrl = clientLogoInBase64 ? ('data:image/png;base64,' + clientLogoInBase64) : '';
                    });
                    $scope.$watch('loginService.disclaimer', function (disclaimer) {
                        $scope.disclaimer = disclaimer;
                    });
                }];
            this.link = function ($scope) {
                $scope.credentialsPromptFinished = function (result) {
                    $scope.errorVisible = false;
                    if (result.error) {
                        $scope.credentialsPromptVisible = true;
                        $scope.uiFactory.showError(result.error, null);
                    }
                    else if ($scope.uiFactory.processCredentials) {
                        $scope.uiFactory.processCredentials.resolve({
                            login: result.login,
                            password: result.password,
                            domain: result.domainId
                        });
                    }
                };
                $scope.siteSelectionFinished = function (result) {
                    $scope.errorVisible = false;
                    if ($scope.uiFactory.processSite) {
                        $scope.uiFactory.processSite.resolve({ site: result.site });
                    }
                };
                $scope.changePasswordFinished = function (result) {
                    $scope.errorVisible = false;
                    if ($scope.uiFactory.processChangePassword) {
                        $scope.uiFactory.processChangePassword.resolve({
                            password: result.newPassword,
                            revisionPassword: result.newRevisionPassword,
                            secretQuestionInfo: result.secretQuestionInfo });
                    }
                };
            };
        }
        LoginComponentDirective.prototype.trimTrailingSlash = function (url) {
            return url.replace(/\/+$/, '');
        };
        LoginComponentDirective.prototype.initializeScope = function (sc, config) {
            sc.credentialsPromptVisible = false;
            sc.credentialsPromptConfig = {
                predefinedLogin: null,
                predefinedDomain: null,
                domains: null
            };
            sc.siteSelectionVisible = false;
            sc.siteSelectionConfig = {
                sites: null
            };
            sc.changePasswordVisible = false;
            sc.changePasswordConfig = {
                passwordInfo: null
            };
            var initialError = config.initialError();
            sc.errorVisible = !_.isEmpty(initialError);
            sc.errorConfig = {
                error: initialError,
                throwable: null
            };
            sc.pcSso = this.config.getPcSso && this.config.getPcSso();
        };
        return LoginComponentDirective;
    }());
    exports.register = function (ngModule, template, config) {
        if (!template) {
            template = require('text!./LoginComponent.html');
        }
        ngModule.directive('csfLoginComponent', function () { return new LoginComponentDirective(template, config); });
    };
});

