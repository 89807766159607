define('login-component/Services/Authenticator',["require", "exports"], function (require, exports) {
    "use strict";
    var Authenticator = (function () {
        function Authenticator(interpolateService, qService, authenticationService, lcI18nService, sessionParams, useSites, system, locale, generateLoginOtp, uiFactory, bidiService) {
            this.interpolateService = interpolateService;
            this.qService = qService;
            this.authenticationService = authenticationService;
            this.lcI18nService = lcI18nService;
            this.sessionParams = sessionParams;
            this.useSites = useSites;
            this.system = system;
            this.locale = locale;
            this.generateLoginOtp = generateLoginOtp;
            this.uiFactory = uiFactory;
            this.bidiService = bidiService;
        }
        Authenticator.prototype.authenticateFromScratch = function () {
            this.authenticationFinished = this.qService.defer();
            this.authenticateAttempt(null);
            return this.authenticationFinished.promise;
        };
        Authenticator.prototype.authenticateOnSSO = function (authResponse) {
            this.authenticationFinished = this.qService.defer();
            if (authResponse.authToken) {
                this.successfullyAuthenticated(authResponse);
                return this.authenticationFinished.promise;
            }
            return this.authenticateFromScratch();
        };
        Authenticator.prototype.authenticateAttempt = function (prevAttemptResult) {
            var _this = this;
            if (prevAttemptResult != null && prevAttemptResult.successfull) {
                this.authenticationFinished.resolve({
                    sessionToken: prevAttemptResult.sessionToken,
                    loginOtp: prevAttemptResult.loginOtp,
                    renewInterval: prevAttemptResult.renewInterval
                });
            }
            else {
                if (prevAttemptResult != null) {
                    this.uiFactory.showError(prevAttemptResult.error, prevAttemptResult.throwable);
                }
                this.authenticatingUserId = undefined;
                this.authenticatingUserPassword = undefined;
                this.uiFactory
                    .askCredentials('', null, this.sessionParams.domains, this.sessionParams.defaultDomain)
                    .then(function (r) { return _this.processCredentials(r); });
            }
        };
        Authenticator.prototype.methodFailedUnexpectedly = function (err) {
            var error = err;
            if (_.isObject(error)) {
                error = typeof error.error === 'string' ? error.error : error.message;
            }
            this.authenticateAttempt({
                successfull: false,
                throwable: error
            });
        };
        Authenticator.prototype.methodFailedExpectedly = function (response) {
            this.authenticateAttempt({
                successfull: false,
                error: response.errorMessage
            });
        };
        Authenticator.prototype.successfullyGetSites = function (response) {
            var _this = this;
            if (!response.errorMessage) {
                if (response.sites && response.sites.length > 0) {
                    if (response.sites.length === 1) {
                        this.processSite({ site: response.sites[0] });
                    }
                    else {
                        var defaultSites = response.sites.filter(function (site) {
                            return site.id === response.defaultSite;
                        });
                        if (defaultSites.length === 1) {
                            this.processSite({ site: defaultSites[0] });
                        }
                        else if (defaultSites.length > 1) {
                            this.uiFactory.askSite(defaultSites).then(function (r) { return _this.processSite(r); });
                        }
                        else {
                            this.uiFactory.askSite(response.sites).then(function (r) { return _this.processSite(r); });
                        }
                    }
                }
                else {
                    var error = this.lcI18nService.getResource('Login.Authenticator.noSitesAvailable.text');
                    error = this.interpolateService(error)({ systemName: this.system });
                    this.methodFailedExpectedly({ errorMessage: error });
                }
            }
            else {
                this.methodFailedExpectedly(response);
            }
        };
        Authenticator.prototype.successfullyAuthenticated = function (response) {
            var _this = this;
            if (!response.errorMessage) {
                var resolved = this.qService.defer();
                var localeAppliedPromise;
                if (this.locale || !response.language) {
                    localeAppliedPromise = resolved.promise;
                }
                else {
                    this.bidiService.setLocale(response.language);
                    localeAppliedPromise = this.lcI18nService.setLocale(response.language);
                }
                resolved.resolve();
                localeAppliedPromise.then(function () {
                    if (response.passwordExpired ||
                        (response.passwordChangingInfo &&
                            response.passwordChangingInfo.useSecretQuestion &&
                            (!response.passwordChangingInfo.secretAnswer || !!response.passwordChangingInfo.secretQuestion))) {
                        _this.uiFactory.askChangePassword(response.passwordChangingInfo).then(function (r) { return _this.processChangePassword(r); });
                    }
                    else {
                        _this.authenticateResponse = response;
                        if (_this.useSites && _this.sessionParams.useSites) {
                            _this.authenticationService
                                .getUserSites()
                                .success(function (sitesResponse) {
                                _this.successfullyGetSites(sitesResponse);
                            })
                                .error(function (error) {
                                _this.methodFailedUnexpectedly(error);
                            });
                        }
                        else {
                            _this.processSite(null);
                        }
                    }
                });
            }
            else {
                this.methodFailedExpectedly(response);
            }
        };
        Authenticator.prototype.successfullyLoggedIn = function (response, site) {
            if (!response.errorMessage) {
                this.authenticateAttempt({
                    successfull: true,
                    sessionToken: response.sessionId,
                    loginOtp: response.loginOtp,
                    renewInterval: response.sessionRenewInterval
                });
            }
            else {
                this.methodFailedExpectedly(response);
            }
        };
        Authenticator.prototype.processCredentials = function (credentials) {
            var _this = this;
            this.authenticatingUserId = credentials.login;
            this.authenticatingUserPassword = credentials.password;
            this.authenticationService
                .authenticate(credentials.login, credentials.password, credentials.domain)
                .success(function (response) {
                _this.successfullyAuthenticated(response);
            })
                .error(function (error) {
                _this.methodFailedUnexpectedly(error);
            });
        };
        Authenticator.prototype.processSite = function (siteResult) {
            var _this = this;
            var site = siteResult ? siteResult.site : null;
            this.authenticationService
                .login(site, this.generateLoginOtp)
                .success(function (response) {
                _this.successfullyLoggedIn(response, site);
            })
                .error(function (error) {
                _this.methodFailedUnexpectedly(error);
            });
        };
        Authenticator.prototype.processChangePassword = function (passwordResult) {
            var _this = this;
            this.authenticationService
                .changePassword(this.authenticatingUserId, this.authenticatingUserPassword, passwordResult.password, passwordResult.revisionPassword, passwordResult.secretQuestionInfo)
                .success(function (response) {
                _this.successfullyAuthenticated(response);
            })
                .error(function (error) {
                _this.methodFailedUnexpectedly(error);
            });
        };
        return Authenticator;
    }());
    exports.Authenticator = Authenticator;
});

