define('login-component/Components/CredentialsForm/CredentialsForm',["require", "exports", "text!./CredentialsForm.html", "less!./CredentialsForm"], function (require, exports) {
    "use strict";
    var findDomain = function (domains, value) {
        var matched = domains.filter(function (domain) {
            return domain.id === value.id;
        });
        return matched.length > 0 ? matched[0] : null;
    };
    var CredentialsFormDirective = (function () {
        function CredentialsFormDirective(template, lcI18nService) {
            var _this = this;
            this.template = template;
            this.lcI18nService = lcI18nService;
            this.scope = { config: '=', resultCallback: '=' };
            this.controller = ["$timeout", function ($timeout) {
                    $timeout(function () { $('[name="login"]').focus(); });
                }];
            this.link = function ($scope, $element) {
                if ($scope.config.domains) {
                    $scope.useDomains = true;
                    $scope.domainOptions = $scope.config.domains;
                }
                var domain = ($scope.domainOptions && $scope.config.predefinedDomain)
                    ? findDomain($scope.domainOptions, { id: $scope.config.predefinedDomain })
                    : null;
                if (!domain) {
                    domain = ($scope.domainOptions && $scope.domainOptions.length > 0)
                        ? $scope.domainOptions[0]
                        : null;
                }
                $scope.credentials = {
                    login: $scope.config.predefinedLogin,
                    domain: domain,
                    password: null
                };
                if ($scope.config.predefinedLogin) {
                    $scope.locked = true;
                }
                $scope.submit = function (submitInvalid) {
                    var loginMissed = !$scope.credentials.login || $scope.credentials.login.trim().length === 0;
                    var domainMissed = $scope.useDomains && !$scope.credentials.domain;
                    var error = null;
                    if (loginMissed || domainMissed) {
                        error = _this.lcI18nService.getResource('Login.Credentials.pleaseFillReqFields.text');
                    }
                    if (!error || submitInvalid) {
                        var result = {
                            login: ($scope.credentials.login) ? $scope.credentials.login.toLowerCase() : null,
                            password: $scope.credentials.password,
                            domainId: ($scope.useDomains) ? $scope.credentials.domain.id : null,
                            error: error
                        };
                        $scope.resultCallback(result);
                        $scope.credentials.password = null;
                    }
                };
            };
        }
        return CredentialsFormDirective;
    }());
    exports.register = function (ngModule, template) {
        if (!template) {
            template = require('text!./CredentialsForm.html');
        }
        ngModule.directive("csfCredentialsForm", ['csfI18nService', function (csfI18nService) { return new CredentialsFormDirective(template, csfI18nService); }]);
    };
});

