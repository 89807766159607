define('login-page/LoginPage',["require", "exports", 'ngModule', 'purl', '../login-component/module', "text!./LoginPage.html", "less!./LoginPage"], function (require, exports, ngModule, purl, loginComponentModule) {
    "use strict";
    var url = purl();
    var addUrlParameter = function (uri, key, value) {
        var hashIndex = uri.indexOf('#');
        var hashPart = hashIndex === -1 ? '' : uri.substr(hashIndex);
        var uriPart = hashIndex === -1 ? uri : uri.substr(0, hashIndex);
        var separator = uriPart.indexOf('?') !== -1 ? '&' : '?';
        uriPart = uriPart + separator + key + '=' + value;
        return uriPart + hashPart;
    };
    var Configuration = (function () {
        function Configuration() {
            this.loginComponentModuleParams = {
                terminalName: url.param('terminalName') || '',
                changeSite: url.param('changeSite') != null,
                locale: url.param('locale'),
                noSso: url.param('noSso') != null,
                pcSso: url.param('pcSso') != null,
                imagesUrl: url.param('images_url') || '',
                theme: url.param('theme') || '',
                permanent: url.param('permanent') != null,
                sessionAttribute: url.param('sessionAttribute'),
                createLockSession: url.param('createLockSession') != null,
                initialError: url.param('errorMessage')
            };
            if (url.param('response_type')) {
                var uri = url.param('redirect_uri');
                var state = url.param('state');
                if (uri && state) {
                    uri = addUrlParameter(uri, 'state', state);
                }
                this.redirectionDetails = {
                    uri: uri,
                    otpParamName: 'code'
                };
                this.ssoGrant = url.param('code');
                var scope = url.param('scope');
                if (scope) {
                    var scopeParts = scope.split(';');
                    this.loginComponentModuleParams.system = (scopeParts[0] || '').toUpperCase();
                    this.loginComponentModuleParams.useSites = scopeParts.length > 1 && scopeParts[1] === 'sited';
                }
            }
            else {
                this.redirectionDetails = {
                    uri: url.param('callback_url'),
                    otpParamName: 'loginOtp'
                };
                (this.ssoGrant = url.fparam('ssoOtp') || url.param('ssoOtp')),
                    (this.loginComponentModuleParams.system = (url.param('system') || '').toUpperCase());
                this.loginComponentModuleParams.useSites = url.param('siteless') == null;
            }
            if (!this.redirectionDetails.uri) {
                this.error = 'Please, specify redirect uri in url parameters ?callback_url=... or ?redirect_uri=...';
            }
            if (!this.loginComponentModuleParams.changeSite &&
                (!this.loginComponentModuleParams.system || this.loginComponentModuleParams.system.trim().length < 1)) {
                this.error = 'Please, specify system in url parameters ?system=SYSTEM or ?scope=SYSTEM;sited';
            }
        }
        return Configuration;
    }());
    var configuration = new Configuration();
    var LoginPageDirective = (function () {
        function LoginPageDirective(redirectionDetails, ssoGrant, error) {
            var _this = this;
            this.redirectionDetails = redirectionDetails;
            this.ssoGrant = ssoGrant;
            this.error = error;
            this.controller = [
                '$scope',
                'csfLoginService',
                '$timeout',
                '$rootScope',
                function ($scope, csfLoginService, $timeout, $rootScope) {
                    var theme = configuration.loginComponentModuleParams.theme;
                    var imagesUrl = configuration.loginComponentModuleParams.imagesUrl;
                    $rootScope.htmlClass = theme ? 'wbc-theme-' + theme : '';
                    $scope.showDefaultLoginPage = !imagesUrl;
                    $scope.loginService = csfLoginService;
                    $scope.$watch('loginService.executionState.executingTask', function (newValue) {
                        if (newValue === true) {
                            $scope.executingTaskTimer = $timeout(function () {
                                $scope.executingTask = true;
                            }, 1000);
                        }
                        else {
                            if ($scope.executingTaskTimer) {
                                $timeout.cancel($scope.executingTaskTimer);
                                $scope.executingTaskTimer = null;
                            }
                            $scope.executingTask = false;
                        }
                    });
                }
            ];
            this.link = function ($scope) {
                $scope.error = _this.error;
                var handleSecurityParams = function (allowedDomains) {
                    if (!_this.isDomainAllowed(_this.redirectionDetails.uri, allowedDomains)) {
                        $scope.error = 'Target Domain is not in list of allowed Domains';
                    }
                };
                if (!$scope.error) {
                    setTimeout(function () {
                        $scope.loginService
                            .login({ generateOtp: true, ssoOtp: _this.ssoGrant, handleSecurityParams: handleSecurityParams })
                            .then(function (result) {
                            location.replace(addUrlParameter(_this.redirectionDetails.uri, _this.redirectionDetails.otpParamName, result.loginOtp));
                        });
                    });
                }
            };
            this.template = require('text!./LoginPage.html');
            this.isDomainAllowed = function (uri, allowedDomains) {
                if (!uri) {
                    return true;
                }
                var targetHostname = purl(uri).attr('host');
                var currentHostname = purl().attr('host');
                return (targetHostname === currentHostname ||
                    targetHostname === 'localhost' ||
                    !targetHostname ||
                    !allowedDomains ||
                    !allowedDomains.length ||
                    allowedDomains.some(function (ad) { return ad === targetHostname; }));
            };
        }
        return LoginPageDirective;
    }());
    var loginPageDirective = new LoginPageDirective(configuration.redirectionDetails, configuration.ssoGrant, configuration.error);
    loginComponentModule.register(ngModule, {
        getSystem: function () { return configuration.loginComponentModuleParams.system; },
        getTerminalName: function () { return configuration.loginComponentModuleParams.terminalName; },
        getUseSites: function () { return configuration.loginComponentModuleParams.useSites; },
        getImagesUrl: function () { return configuration.loginComponentModuleParams.imagesUrl; },
        getTheme: function () { return configuration.loginComponentModuleParams.theme; },
        getChangeSite: function () { return configuration.loginComponentModuleParams.changeSite; },
        getBaseUrl: function () { return ''; },
        getLocale: function () { return configuration.loginComponentModuleParams.locale; },
        getNoSso: function () { return configuration.loginComponentModuleParams.noSso; },
        getPcSso: function () { return configuration.loginComponentModuleParams.pcSso && !configuration.loginComponentModuleParams.noSso; },
        getI18nService: function () { return null; },
        getSessionRenewable: function () { return !configuration.loginComponentModuleParams.permanent; },
        getSessionAttribute: function () { return configuration.loginComponentModuleParams.sessionAttribute; },
        getCreateLockSession: function () { return configuration.loginComponentModuleParams.createLockSession; },
        initialError: function () { return configuration.loginComponentModuleParams.initialError; }
    });
    ngModule.directive('loginPage', function () { return loginPageDirective; });
});

