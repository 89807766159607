define('login-component/Services/SecurityService',["require", "exports"], function (require, exports) {
    "use strict";
    var SecurityService = (function () {
        function SecurityService($http, config, bidiService) {
            var _this = this;
            this.$http = $http;
            this.config = config;
            this.bidiService = bidiService;
            this.getUrl = function () {
                return config.getBaseUrl() + 'rest';
            };
            this.executionState = { executingTask: false };
            this.getSystem = function () {
                var configSystem = config.getSystem();
                var otpSystem = _this.otpSessionInfo ? _this.otpSessionInfo.systemName : null;
                return configSystem || otpSystem;
            };
            this.getSessionRenewRequired = function () {
                return _this.config.getSessionRenewable
                    ? _this.config.getSessionRenewable()
                    : _this.otpSessionInfo
                        ? _this.otpSessionInfo.sessionRenewRequired
                        : true;
            };
            this.getSessionCustomAttribute = function () {
                return _this.config.getSessionAttribute && _this.config.getSessionAttribute()
                    ? _this.config.getSessionAttribute()
                    : _this.otpSessionInfo
                        ? _this.otpSessionInfo.sessionCustomAttribute
                        : 'CSF.LoginComponent';
            };
        }
        SecurityService.prototype.createRequestHeaders = function () {
            var headerLocale = this.config.getLocale();
            return {
                locale: headerLocale
            };
        };
        SecurityService.prototype.readSessionParams = function () {
            var _this = this;
            var request = {
                terminalName: this.config.getTerminalName(),
                systemName: this.getSystem()
            };
            var promise = this.$http.post(this.getUrl() + '/params', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise
                .success(function (response, status) {
                if (status === 200) {
                    _this.applySessionParams(response);
                }
            })
                .finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.applySessionParams = function (response) {
            this.bidiService.setLocales(response.locales);
            if (this.config.getTerminalName() || !this.terminalId) {
                this.terminalId = response.terminalId;
            }
        };
        SecurityService.prototype.authenticate = function (login, password, domain) {
            var _this = this;
            var request = {
                login: login,
                password: password,
                domain: domain,
                terminalId: this.terminalId,
                systemName: this.getSystem()
            };
            var promise = this.$http.post(this.getUrl() + '/authenticate', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise
                .success(function (response, status) {
                if (status === 200) {
                    _this.applyAuthenticate(response);
                }
            })
                .finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.applyAuthenticate = function (response) {
            if (!response.passwordExpired) {
                this.authToken = response.authToken;
            }
            else {
                response.passwordChangingInfo.primaryPasswordExpired = true;
            }
        };
        SecurityService.prototype.getUserSites = function () {
            var _this = this;
            var request = {
                authToken: this.authToken,
                systemName: this.getSystem()
            };
            var language = (this.config.getLocale() || '').slice(0, 2);
            if (language) {
                request.language = language;
            }
            var promise = this.$http.post(this.getUrl() + '/getUserSites', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise.finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.login = function (site, generateLoginOtp) {
            var _this = this;
            var lang = (this.config.getLocale() || '').slice(0, 2);
            if (lang && !site) {
                site = { id: '', languageId: lang };
            }
            var request = {
                authToken: this.authToken,
                site: site,
                terminalId: this.terminalId,
                systemName: this.getSystem(),
                generateLoginOtp: generateLoginOtp,
                sessionRenewRequired: this.getSessionRenewRequired(),
                sessionCustomAttribute: this.getSessionCustomAttribute(),
                createLockSession: this.config.getCreateLockSession()
            };
            var promise = this.$http.post(this.getUrl() + '/login', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise
                .success(function (response, status) {
                if (status === 200) {
                    _this.applyLogin(response);
                }
            })
                .finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.loginSSO = function (ssoOtp, useSites, generateLoginOtp, ignoreCookies, authToken, userId, site) {
            var _this = this;
            var lang = (this.config.getLocale() || '').slice(0, 2);
            if (lang && !site) {
                site = { id: '', languageId: lang };
            }
            var request = {
                terminalName: this.config.getTerminalName(),
                systemName: this.getSystem(),
                siteless: !useSites,
                otp: ssoOtp,
                ignoreCookies: ignoreCookies,
                authToken: authToken,
                userId: userId,
                site: site,
                generateLoginOtp: generateLoginOtp,
                sessionRenewRequired: this.getSessionRenewRequired(),
                sessionCustomAttribute: this.getSessionCustomAttribute(),
                createLockSession: this.config.getCreateLockSession()
            };
            var promise = this.$http.post(this.getUrl() + '/loginSSO', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise
                .success(function (response, status) {
                if (status === 200) {
                    if (response.paramsResult) {
                        _this.applySessionParams(response.paramsResult);
                    }
                    if (response.authenticationResult) {
                        _this.applyAuthenticate(response.authenticationResult);
                    }
                    if (response.loginResult) {
                        _this.applyLogin(response.loginResult);
                    }
                    if (response.otpSessionInfo) {
                        _this.otpSessionInfo = response.otpSessionInfo;
                    }
                }
            })
                .finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.getLoginInfoByOtp = function (ssoOtp) {
            var _this = this;
            var request = {
                otp: ssoOtp
            };
            var promise = this.$http.post(this.getUrl() + '/getInfoForLoginByOtp', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise
                .success(function (response, status) {
                if (status === 200) {
                    if (response.authenticationResult && response.paramsResult) {
                        _this.otpSessionInfo = response.otpSessionInfo;
                        response.paramsResult.defaultLocale = response.otpSessionInfo.locale;
                        _this.applyAuthenticate(response.authenticationResult);
                        _this.applySessionParams(response.paramsResult);
                    }
                }
            })
                .finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.applyLogin = function (response) {
            this.sessionId = response.sessionId;
        };
        SecurityService.prototype.renewSession = function (userActive) {
            var _this = this;
            var request = {
                sessionId: this.sessionId,
                userActive: userActive
            };
            var promise = this.$http.post(this.getUrl() + '/renewSession', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise.finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.logout = function () {
            var _this = this;
            var request = {
                sessionId: this.sessionId
            };
            var promise = this.$http.post(this.getUrl() + '/logoutUser', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise.finally(function () {
                _this.authToken = null;
                _this.sessionId = null;
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.logoutSession = function () {
            var _this = this;
            var request = {
                sessionId: this.sessionId
            };
            var promise = this.$http.post(this.getUrl() + '/logoutSession', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise.finally(function () {
                _this.authToken = null;
                _this.sessionId = null;
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        SecurityService.prototype.changePassword = function (login, oldPassword, newPassword, newRevisionPassword, secretQuestionsInfo) {
            var _this = this;
            var request = {
                login: login,
                oldPassword: oldPassword,
                newPassword: newPassword,
                newRevPassword: newRevisionPassword,
                terminalId: this.terminalId,
                systemName: this.getSystem(),
                secretQuestionInfo: secretQuestionsInfo
            };
            var promise = this.$http.post(this.getUrl() + '/changePassword', request, {
                headers: this.createRequestHeaders()
            });
            this.executionState.executingTask = true;
            promise
                .success(function (response, status) {
                if (status === 200) {
                    _this.applyAuthenticate(response);
                }
            })
                .finally(function () {
                _this.executionState.executingTask = false;
            });
            return promise;
        };
        return SecurityService;
    }());
    exports.register = function (ngModule, config) {
        ngModule.factory('csfSecurityService', [
            '$http', 'bidirectionalService',
            function ($http, bidiService) {
                return new SecurityService($http, config, bidiService);
            }
        ]);
    };
});

