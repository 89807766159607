define('login-component/localization/LCi18nService',["require", "exports", 'lodash', "text!login-component/string-resources/string-resources.json", "text!login-component/string-resources/fr/string-resources.json", "text!login-component/string-resources/pt/string-resources.json", "text!login-component/string-resources/fr_CH/string-resources.json", "text!login-component/string-resources/he/string-resources.json"], function (require, exports, _) {
    "use strict";
    var LCi18nService = (function () {
        function LCi18nService(i18nService, defaultResources, resourcesByLocale, qService) {
            this.i18nService = i18nService;
            this.defaultResources = defaultResources;
            this.resourcesByLocale = resourcesByLocale;
            this.qService = qService;
            this.currentResources = defaultResources;
        }
        LCi18nService.prototype.getResource = function (key) {
            if (this.i18nService) {
                var value = this.i18nService.getResource(key);
                if (value && value !== key) {
                    return value;
                }
            }
            return this.currentResources[key];
        };
        LCi18nService.prototype.setLocale = function (locale) {
            var deferedResult = this.qService.defer();
            if (locale) {
                var parentLocale = locale.slice(0, 2);
                if (locale === parentLocale) {
                    this.currentResources = _.merge({}, this.defaultResources, this.resourcesByLocale[locale]);
                }
                else {
                    this.currentResources = _.merge({}, this.defaultResources, this.resourcesByLocale[parentLocale], this.resourcesByLocale[locale]);
                }
                if (this.i18nService) {
                    return this.i18nService.setLocale(locale);
                }
                else {
                    deferedResult.resolve();
                }
            }
            else {
                this.currentResources = this.defaultResources;
                deferedResult.reject('Locale is not specified.');
            }
            return deferedResult.promise;
        };
        return LCi18nService;
    }());
    exports.register = function (ngModule, config) {
        ngModule.factory('csfI18nService', [
            '$q',
            function (qService) {
                var stringResourcesText = require('text!login-component/string-resources/string-resources.json');
                var defaultResources = JSON.parse(stringResourcesText);
                stringResourcesText = require('text!login-component/string-resources/fr/string-resources.json');
                var frStringResources = JSON.parse(stringResourcesText);
                stringResourcesText = require('text!login-component/string-resources/pt/string-resources.json');
                var ptStringResources = JSON.parse(stringResourcesText);
                stringResourcesText = require('text!login-component/string-resources/fr_CH/string-resources.json');
                var frCHStringResources = JSON.parse(stringResourcesText);
                stringResourcesText = require('text!login-component/string-resources/he/string-resources.json');
                var heStringResources = JSON.parse(stringResourcesText);
                var resourcesByLocale = {
                    en: defaultResources,
                    fr: frStringResources,
                    pt: ptStringResources,
                    fr_CH: frCHStringResources,
                    he: heStringResources
                };
                var lcI18nService = new LCi18nService(config.getI18nService(), defaultResources, resourcesByLocale, qService);
                lcI18nService.setLocale(config.getLocale());
                return lcI18nService;
            }
        ]);
    };
});

