define('login-component/Components/ChangePasswordForm/ChangePasswordForm',["require", "exports", "text!./ChangePasswordForm.html", "less!./ChangePasswordForm", "select2", "css!select2"], function (require, exports) {
    "use strict";
    var RegexRule = (function () {
        function RegexRule(caption, pattern) {
            this.caption = caption;
            this.match = function (password) {
                return (password || '').match(pattern) != null;
            };
        }
        return RegexRule;
    }());
    var RequiredCharactersRule = (function () {
        function RequiredCharactersRule(caption, specialCharacters) {
            this.caption = caption;
            this.match = function (password) {
                if (!password) {
                    return false;
                }
                if (!specialCharacters) {
                    return true;
                }
                for (var i = 0, len = specialCharacters.length; i < len; i++) {
                    if (specialCharacters[i] && password.indexOf(specialCharacters[i]) >= 0) {
                        return true;
                    }
                }
            };
        }
        return RequiredCharactersRule;
    }());
    var ReservedWordsRule = (function () {
        function ReservedWordsRule(caption, reservedWords) {
            this.caption = caption;
            this.match = function (password) {
                if (!password) {
                    return true;
                }
                if (!reservedWords) {
                    return true;
                }
                for (var i = 0, len = reservedWords.length; i < len; i++) {
                    var caseInsensitivePattern = new RegExp(reservedWords[i], 'i');
                    if (reservedWords[i] && password.match(caseInsensitivePattern) != null) {
                        return false;
                    }
                }
                return true;
            };
        }
        return ReservedWordsRule;
    }());
    var ConfirmRule = (function () {
        function ConfirmRule(caption) {
            this.caption = caption;
            this.match = function (password, confirm) {
                return password === confirm;
            };
        }
        return ConfirmRule;
    }());
    var ChangePasswordFormDirective = (function () {
        function ChangePasswordFormDirective(template, templateSubScopeProvider, lcI18nService) {
            var _this = this;
            this.template = template;
            this.templateSubScopeProvider = templateSubScopeProvider;
            this.lcI18nService = lcI18nService;
            this.scope = { config: '=', resultCallback: '=' };
            this.controller = [
                '$timeout',
                function ($timeout) {
                    $timeout(function () {
                        $('[name="newPassword"]').focus();
                    });
                }
            ];
            this.link = function ($scope, $element) {
                $scope.revisionPasswordRequired = $scope.config.passwordInfo.changeRevisionPassword;
                $scope.useSecretQuestions = $scope.config.passwordInfo.useSecretQuestion;
                if ($scope.useSecretQuestions) {
                    $scope.predefinedSecretQuestions = $scope.config.passwordInfo.predefinedQuestions;
                    $scope.secretQuestion = $scope.config.passwordInfo.secretQuestion;
                    $scope.secretAnswer = $scope.config.passwordInfo.secretAnswer;
                    if ($scope.secretQuestion && $scope.predefinedSecretQuestions.indexOf($scope.secretQuestion) == -1) {
                        $scope.predefinedSecretQuestions.push($scope.secretQuestion);
                    }
                }
                var rules = [];
                var revisionRules = [];
                var createRules = function () {
                    var changingPasswordInfo = $scope.config.passwordInfo;
                    rules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.passwordLengthMustBe.text') +
                        ' ' +
                        changingPasswordInfo.minPasswordLength +
                        ' - ' +
                        changingPasswordInfo.maxPasswordLength +
                        ' ' +
                        _this.lcI18nService.getResource('Login.ChangePassword.characters.text'), '^.{' + changingPasswordInfo.minPasswordLength + ',' + changingPasswordInfo.maxPasswordLength + '}$'));
                    if ($scope.config.passwordInfo.validateRevisionPassword) {
                        revisionRules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.ReviewPasswordLenthMustBe.text') +
                            ' ' +
                            changingPasswordInfo.minPasswordLength +
                            ' - ' +
                            changingPasswordInfo.maxPasswordLength +
                            ' ' +
                            _this.lcI18nService.getResource('Login.ChangePassword.characters.text'), '^.{' + changingPasswordInfo.minPasswordLength + ',' + changingPasswordInfo.maxPasswordLength + '}$'));
                    }
                    if (changingPasswordInfo.useUpperCase) {
                        rules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.passwordNeedsUppercaseCharacter.text'), '[A-Z]+'));
                        if ($scope.config.passwordInfo.validateRevisionPassword) {
                            revisionRules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.reviewPasswordNeedsUppercaseCharacter.text'), '[A-Z]+'));
                        }
                    }
                    if (changingPasswordInfo.useLowerCase) {
                        rules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.passwordNeedsLowercaseCharacter.text'), '[a-z]+'));
                        if ($scope.config.passwordInfo.validateRevisionPassword) {
                            revisionRules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.reviewPasswordNeedsLowercaseCharacter.text'), '[a-z]+'));
                        }
                    }
                    if (changingPasswordInfo.useNumbers) {
                        rules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.passwordNeedsDigitCharacter.text'), '[0-9]+'));
                        if ($scope.config.passwordInfo.validateRevisionPassword) {
                            revisionRules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.reviewPasswordNeedsDigitCharacter.text'), '[0-9]+'));
                        }
                    }
                    if (changingPasswordInfo.useSpecialCharacters) {
                        rules.push(new RequiredCharactersRule(_this.lcI18nService.getResource('Login.ChangePassword.passwordNeedsSpecialCharacter.text'), changingPasswordInfo.specialCharacters));
                        if ($scope.config.passwordInfo.validateRevisionPassword) {
                            revisionRules.push(new RequiredCharactersRule(_this.lcI18nService.getResource('Login.ChangePassword.reviewPasswordNeedsSpecialCharacter.text'), changingPasswordInfo.specialCharacters));
                        }
                    }
                    rules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.passwordAscii.text'), '^[\u0020-\u007F]*$'));
                    revisionRules.push(new RegexRule(_this.lcI18nService.getResource('Login.ChangePassword.reviewPasswordAscii.text'), '^[\u0020-\u007F]*$'));
                    if (Array.isArray(changingPasswordInfo.forbiddenPatterns) && changingPasswordInfo.forbiddenPatterns.length) {
                        rules.push(new ReservedWordsRule(_this.lcI18nService.getResource('Login.ChangePassword.passwordCannotUseID.text'), changingPasswordInfo.forbiddenPatterns));
                        if ($scope.config.passwordInfo.validateRevisionPassword) {
                            revisionRules.push(new ReservedWordsRule(_this.lcI18nService.getResource('Login.ChangePassword.reviewPasswordCannotUseId.text'), changingPasswordInfo.forbiddenPatterns));
                        }
                    }
                    rules.push(new ConfirmRule(_this.lcI18nService.getResource('Login.ChangePassword.reenterNewPassword.text')));
                    revisionRules.push(new ConfirmRule(_this.lcI18nService.getResource('Login.ChangePassword.reenterNewReviewPassword.text')));
                };
                var refreshRules = function () {
                    var changingPasswordInfo = $scope.config.passwordInfo;
                    $scope.validatedRules = [];
                    if (changingPasswordInfo.primaryPasswordExpired || $scope.newPassword) {
                        rules.forEach(function (rule) {
                            var match = rule.match($scope.newPassword, $scope.confirmPassword);
                            $scope.validatedRules.push({ text: rule.caption, passed: match });
                        });
                    }
                    if ($scope.revisionPasswordRequired && $scope.newRevisionPassword) {
                        revisionRules.forEach(function (rule) {
                            var match = rule.match($scope.newRevisionPassword, $scope.confirmRevisionPassword);
                            $scope.validatedRules.push({ text: rule.caption, passed: match });
                        });
                        var revisionPasswordDiffers = $scope.newRevisionPassword !== $scope.newPassword;
                        $scope.validatedRules.push({
                            text: _this.lcI18nService.getResource('Login.ChangePassword.ReviewPasswordMustBeDifferent.text'),
                            passed: revisionPasswordDiffers
                        });
                    }
                    if ($scope.useSecretQuestions) {
                        var questionsFilled = $scope.secretQuestion && $scope.secretAnswer ? true : false;
                        $scope.validatedRules.push({
                            text: _this.lcI18nService.getResource('Login.ChangePassword.SecretAnswerRequired.text'),
                            passed: questionsFilled
                        });
                    }
                };
                createRules();
                if (_this.templateSubScopeProvider) {
                    $scope.templateSubScope = _this.templateSubScopeProvider($scope);
                }
                refreshRules();
                $scope.$watch('confirmPassword', refreshRules);
                $scope.$watch('newPassword', refreshRules);
                $scope.$watch('confirmRevisionPassword', refreshRules);
                $scope.$watch('newRevisionPassword', refreshRules);
                $scope.$watch('secretQuestion', refreshRules);
                $scope.$watch('secretAnswer', refreshRules);
                $scope.hasValidErrors = false;
                $scope.submit = function () {
                    if (!$scope.validatedRules.some(function (vr) {
                        return !vr.passed;
                    })) {
                        $scope.resultCallback({
                            newPassword: $scope.newPassword,
                            newRevisionPassword: $scope.newRevisionPassword,
                            secretQuestionInfo: { secretQuestion: $scope.secretQuestion, secretAnswer: $scope.secretAnswer }
                        });
                    }
                    else {
                        $scope.hasValidErrors = true;
                    }
                };
            };
        }
        return ChangePasswordFormDirective;
    }());
    var changePasswordSubScopeProvider = function (scope) {
        if (scope.useSecretQuestions) {
            var lookupData = scope.predefinedSecretQuestions.map(function (item) {
                return { id: item, text: item };
            });
            var mySecretQuestion = _.find(lookupData, function (item) {
                return item.id == scope.secretQuestion;
            });
            var mySecretQuestionOptions = {
                placeholder: _injector.get('csfI18nService').getResource('ChangePassword.secretQuestion.placeholder'),
                maximumInputLength: 128,
                multiple: false,
                createSearchChoice: function (term, data) {
                    if ($(data).filter(function () {
                        return this.text.localeCompare(term) === 0;
                    }).length === 0) {
                        return { id: term, text: term };
                    }
                },
                data: lookupData
            };
            var element = $('#loginComponentChangePasswordSecretQuestion');
            element.select2(mySecretQuestionOptions);
            element.select2('data', mySecretQuestion, false);
            element.bind('change', function () {
                var selected = element.select2('data');
                if (selected) {
                    scope.secretQuestion = selected.id;
                    scope.$digest();
                }
            });
        }
        return {};
    };
    var _injector;
    exports.register = function (ngModule, template, templateSubScopeProvider) {
        if (!template) {
            template = require('text!./ChangePasswordForm.html');
        }
        if (!templateSubScopeProvider) {
            templateSubScopeProvider = changePasswordSubScopeProvider;
        }
        ngModule.directive('csfChangePasswordForm', [
            '$injector',
            'csfI18nService',
            function ($injector, csfI18nService) {
                _injector = $injector;
                return new ChangePasswordFormDirective(template, templateSubScopeProvider, csfI18nService);
            }
        ]);
    };
});

