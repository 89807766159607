define('login-component/module',["require", "exports", './Components/CredentialsForm/CredentialsForm', './Components/ErrorsContainer/ErrorsContainer', './Components/ChangePasswordForm/ChangePasswordForm', './Components/SiteSelectionForm/SiteSelectionForm', './Components/LoginComponent/LoginComponent', './Services/SecurityService', './Services/LoginService', './localization/LCi18nService', './localization/Localize', './Services/BidirectionalService'], function (require, exports, credentialsFormComponent, errorsContainerComponent, changePasswordFormComponent, siteSelectionFormComponent, loginComponent, securityService, loginService, lcI18nService, localizeDirective, bidirectionalService) {
    "use strict";
    exports.register = function (ngModule, config) {
        var templates = {};
        if (config.getTemplates) {
            templates = config.getTemplates();
        }
        lcI18nService.register(ngModule, config);
        localizeDirective.register(ngModule);
        bidirectionalService.register(ngModule);
        credentialsFormComponent.register(ngModule, templates.credentialsPrompt);
        errorsContainerComponent.register(ngModule, templates.errorsContainer);
        siteSelectionFormComponent.register(ngModule, templates.siteSelection);
        changePasswordFormComponent.register(ngModule, templates.changePassword, templates.changePasswordSubScopeProvider);
        loginComponent.register(ngModule, templates.layout, config);
        securityService.register(ngModule, config);
        loginService.register(ngModule, config);
    };
});

